import React from 'react';
import {getClassName} from "../../utils/StyleUtils";

interface Props {
    onBack?: (() => void) | undefined;
    isShowBack?: boolean;
    isShowLabel?: boolean;
    onGetPreset?: (() => void) | undefined
}

export const HeaderOnboarding = ({onBack, isShowBack, isShowLabel, onGetPreset}: Props) => {
    return <div className={getClassName('header-onboarding')} style={{zIndex: 9999}}>
        {isShowBack ? <div className={'back'} onClick={() => {
            if(onBack) {
                onBack()
            }
        }}>Back</div> : null}
        <div style={{zIndex: -1, display: 'flex', justifyContent: (isShowBack ? 'center': 'none')}} className={getClassName('fltr-onboarding-logo-container')}>
            <img className={getClassName('fltr-onboarding-logo')}
                 src={require('../../resources/imgs/logo.png')}
            />
            {isShowLabel ? <img className={getClassName('get-preset')}
                                                onClick={() => {
                                                    if(onGetPreset) {
                                                        onGetPreset()
                                                    }
                                                }}
                                                src={require('../../resources/imgs/get-preset.webp')}
            /> : null}
        </div>
    </div>
}
