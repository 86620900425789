import React, {useEffect, useState} from 'react';
import {PaywallResources, Resource, ResourceType, ReviewResources} from '../../models/Resources';
import {PaywallOffer} from './PaywallOffer';
import {PaywallBody} from './PaywallBody';
import {preloadResource} from '../../utils/URLUtils';
import MDSpinner from 'react-md-spinner';
import {getClassName, spinnerColor} from '../../utils/StyleUtils';
import {HeaderOnboarding} from "../general/HeaderOnboarding";
import {DescriptionApp} from "./DescriptionApp";
import {fltrProducts} from "../../models/Product";
import {GTMEvents} from "../../models/GTM";
import {useHistory} from "react-router-dom";
import {Reviews} from "../main/Reviews";
import {Questions} from "../main/Questions";

const Paywall = ({}) => {
    const [isLoadingResources, setIsLoadingResources] = useState(true)
    const [stepNumber, setStepNumber] = useState(2)
    const [selectedProductId, selectProductId] = useState<string>(fltrProducts[1].id);

    useEffect(() => {
        const loadResources = (resource: Resource) => {
            return new Promise((resolve, reject) => {
              if(resource.type === ResourceType.VIDEO) {
                 resolve(null);
              } else {
                preloadResource(resource.src).then((src) => {
                  resource.blobSrc = src;
                  resolve(src);
                })
              }
            })
        }

         Promise.all(
             Object.values(PaywallResources).concat(
                 Object.values(ReviewResources)
             )
             .map(resource => loadResources(resource)))
            .then(() => setIsLoadingResources(false))
            .catch(err => console.log("Failed to load images", err))
    }, [])

    let history = useHistory();

    const onGetPreset = () => {
        const product = fltrProducts.find(product => product.id === selectedProductId);
        if(product) {
            // @ts-ignore
            window.dataLayer.push({ 'event': GTMEvents.CHECKOUT,
                'ecommerce': {
                    'checkout': {
                        'products': [{
                            'name': product.description,
                            'id': product.id,
                            'price': product.amount / 100,
                            'quantity': 1
                        }]
                    }
                }});
        }
        history.push(`/payment/${selectedProductId}`);
    }

    const renderGetPresetsBtn = (style = {}) => {
        return <button id={'Checkout'} className={getClassName('general-btn')}
                       style={{...style, ...{marginLeft: '24px', marginRight: '24px', width: 'none'}}}
                       onClick={onGetPreset}
        >
            GET PRESETS
            <div className={'arrow-btn'}/>
        </button>
    }

  if(isLoadingResources) {
      return <div className={'paywall-page-container'}>
          <HeaderOnboarding
              isShowLabel={true}
              onGetPreset={() => onGetPreset()}
          />
          <div className='spinner-container' style={{height: '100VH'}}>
              <MDSpinner size={100} singleColor={spinnerColor}/>
          </div>
      </div>
  }
  return (
      <div className={'paywall-page-container'}>
          <HeaderOnboarding
              isShowLabel={true}
              onGetPreset={() => onGetPreset()}
          />
          <PaywallOffer/>
          <PaywallBody
              selectedProductId={selectedProductId}
              selectProductId={selectProductId}
          />
          {renderGetPresetsBtn()}

          <img className={getClassName('pay-safe')}
               src={require('../../resources/imgs/pay-safe.webp')}
          />
          <DescriptionApp/>

          {renderGetPresetsBtn({marginTop: '32px', marginBottom: '48px'})}
          <Reviews/>
          <Questions/>

          {renderGetPresetsBtn({marginTop: '32px', marginBottom: '65px'})}

      </div>
  );
};

export default Paywall;
