import React, {useEffect, useRef, useState} from 'react';
import {getClassName} from "../../utils/StyleUtils";
import {PaywallProducts} from "./PaywallProducts";
import {PageName} from "../../models/Common";
import {GTMEvents} from '../../models/GTM';

interface Props {
  selectProductId: (productId: string) => void
  selectedProductId: string
}
export const PaywallBody = ({selectedProductId, selectProductId}: Props) => {


  useEffect(() => {
    // @ts-ignore
    if(window.dataLayer) {
      // @ts-ignore
      window.dataLayer.push({ 'event': GTMEvents.LEAD});
      // @ts-ignore
      window.dataLayer.push({ 'event': PageName.PAYWALL_PRODUCTS });
    }
  }, [])

  return <div className={getClassName('paywall-body')}>
    <PaywallProducts
        selectedProductId={selectedProductId}
        selectProductId={selectProductId}
    />
  </div>;
}

