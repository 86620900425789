export enum ResourceType {
    IMAGE = "IMAGE",
    VIDEO = 'VIDEO'
}

export interface Resource {
    src: string,
    blobSrc?: string,
    type: ResourceType,
}
export type ResourceName = string;
export type Resources = {[id: ResourceName]: Resource}

export const MainResources: Resources = {
    iPhone: {src: require('../resources/imgs/iPhone.webp'), type: ResourceType.IMAGE},
    mainBefore: {src: require('../resources/imgs/main-after.webp'), type: ResourceType.IMAGE},
    mainAfter: {src: require('../resources/imgs/main-before.webp'), type: ResourceType.IMAGE},
    mainSocialProof: {src: require('../resources/imgs/main-social-proof.webp'), type: ResourceType.IMAGE},
    footerStoreApple: {src: require('../resources/imgs/footer-store-apple.webp'), type: ResourceType.IMAGE},
    footerStoreGoogle: {src: require('../resources/imgs/footer-store-google.webp'), type: ResourceType.IMAGE},
    instagramFollow: {src: require('../resources/imgs/follow-instagram.webp'), type: ResourceType.IMAGE},
    tryApp: {src: require('../resources/imgs/try-app.webp'), type: ResourceType.IMAGE},
    len1: {src: require('../resources/videos/len1.mp4'), type: ResourceType.VIDEO},
    len2: {src: require('../resources/videos/len2.mp4'), type: ResourceType.VIDEO},
}

export const FeaturesResources: Resources = {
    activeIcon1: {src: require('../resources/imgs/features/active-icon-1.webp'), type: ResourceType.IMAGE},
    activeIcon2: {src: require('../resources/imgs/features/active-icon-2.webp'), type: ResourceType.IMAGE},
    activeIcon3: {src: require('../resources/imgs/features/active-icon-3.webp'), type: ResourceType.IMAGE},
    activeIcon4: {src: require('../resources/imgs/features/active-icon-4.webp'), type: ResourceType.IMAGE},
    activeIcon5: {src: require('../resources/imgs/features/active-icon-5.webp'), type: ResourceType.IMAGE},

    icon1: {src: require('../resources/imgs/features/icon-1.webp'), type: ResourceType.IMAGE},
    icon2: {src: require('../resources/imgs/features/icon-2.webp'), type: ResourceType.IMAGE},
    icon3: {src: require('../resources/imgs/features/icon-3.webp'), type: ResourceType.IMAGE},
    icon4: {src: require('../resources/imgs/features/icon-4.webp'), type: ResourceType.IMAGE},
    icon5: {src: require('../resources/imgs/features/icon-5.webp'), type: ResourceType.IMAGE},

    background1: {src: require('../resources/imgs/features/background-1.webp'), type: ResourceType.IMAGE},
    background2: {src: require('../resources/imgs/features/background-2.webp'), type: ResourceType.IMAGE},
    background3: {src: require('../resources/imgs/features/background-3.webp'), type: ResourceType.IMAGE},
    background4: {src: require('../resources/imgs/features/background-4.webp'), type: ResourceType.IMAGE},
    background5: {src: require('../resources/imgs/features/background-5.webp'), type: ResourceType.IMAGE},
}

export const ReviewResources: Resources = {
    review1: {src: require('../resources/imgs/review/review_1.webp'), type: ResourceType.IMAGE},
    review2: {src: require('../resources/imgs/review/review_2.webp'), type: ResourceType.IMAGE},
    review3: {src: require('../resources/imgs/review/review_3.webp'), type: ResourceType.IMAGE},
    review4: {src: require('../resources/imgs/review/review_4.webp'), type: ResourceType.IMAGE},
    review5: {src: require('../resources/imgs/review/review_5.webp'), type: ResourceType.IMAGE},
    reviewRating: {src: require('../resources/imgs/review/review_rating.webp'), type: ResourceType.IMAGE},
}

export const QuizResources: Resources = {
    iPhone: {src: require('../resources/imgs/iPhone.webp'), type: ResourceType.IMAGE},
    quizMainBefore: {src: require('../resources/imgs/quiz-main-before.webp'), type: ResourceType.IMAGE},
    quizMainAfter: {src: require('../resources/imgs/quiz-main-after.webp'), type: ResourceType.IMAGE},
    quizMainSocialProof: {src: require('../resources/imgs/quiz-main-social-proof.webp'), type: ResourceType.IMAGE},
    quizFinishVideo: {src: require('../resources/videos/quiz-finish.mp4'), type: ResourceType.VIDEO},
    quizCover1: {src: require('../resources/imgs/quiz/quiz-cover-1.webp'), type: ResourceType.IMAGE},
    quizCover2: {src: require('../resources/imgs/quiz/quiz-cover-2.webp'), type: ResourceType.IMAGE},
    quizCover3: {src: require('../resources/imgs/quiz/quiz-cover-3.webp'), type: ResourceType.IMAGE},
    quizCover4: {src: require('../resources/imgs/quiz/quiz-cover-4.webp'), type: ResourceType.IMAGE},
    quizCover5: {src: require('../resources/imgs/quiz/quiz-cover-5.webp'), type: ResourceType.IMAGE},
    quizCover6: {src: require('../resources/imgs/quiz/quiz-cover-6.webp'), type: ResourceType.IMAGE},
    quizCover7: {src: require('../resources/imgs/quiz/quiz-cover-7.webp'), type: ResourceType.IMAGE},
}

export const PaywallResources: Resources = {
    offerBackground: {src: require('../resources/videos/paywall.mp4'), type: ResourceType.VIDEO},
    beforeAfter1: {src: require('../resources/imgs/before-after/1.webp'), type: ResourceType.IMAGE},
    beforeAfter2: {src: require('../resources/imgs/before-after/2.webp'), type: ResourceType.IMAGE},
    beforeAfter3: {src: require('../resources/imgs/before-after/3.webp'), type: ResourceType.IMAGE},
    beforeAfter4: {src: require('../resources/imgs/before-after/4.webp'), type: ResourceType.IMAGE},
}

export const AllResources: Resources = {...MainResources, ...FeaturesResources, ...ReviewResources}
