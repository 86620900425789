import React, { useEffect, useRef, useState } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { selectProduct, setIsShowPaymentForm } from "../../actions/general";
import { SolidgateMerchantData } from "../../models/Solidgate";
import SolidgateForm from "./SolidgateForm";
import MDSpinner from "react-md-spinner";
import { getClassName, spinnerColor } from "../../utils/StyleUtils";
import { PayPalView } from "./PayPalView";
import { OrderSummary } from "./OrderSummary";
import { fltrProducts, Product } from "../../models/Product";
import { PaymentSuccessful } from "./PaymentSuccessful";
import { PaymentFailed } from "./PaymentFailed";
import { PageName } from "../../models/Common";
import { Header } from "../general/Header";
import { useHistory } from "react-router-dom";
import {HeaderOnboarding} from "../general/HeaderOnboarding";

interface StateProps {
  isShowPaymentForm: boolean,
  merchantData: SolidgateMerchantData,
  isLoadingMerchantDate: boolean,
  payPalScriptUrl?: string,
  selectedProduct?: Product,
  redirectAppUrl?: string,
  orderId?: string
}

interface DispatchProps {
  selectProduct: (product: Product) => void
  setIsShowPaymentForm: (isShow: boolean) => void
}

interface OwnProps {
  match: any
}

type Props = StateProps & DispatchProps & OwnProps

const Payment = ({ match, selectProduct, selectedProduct, isShowPaymentForm, setIsShowPaymentForm,
  merchantData, isLoadingMerchantDate, payPalScriptUrl, redirectAppUrl, orderId }: Props) => {
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const [isPaymentFailed, setIsPaymentFailed] = useState(false);

  const onSuccess = () => {
    setIsPaymentSuccessful(true);
    setIsPaymentFailed(false);
    setIsShowPaymentForm(false);
  }

  const onFail = () => {
    setIsPaymentFailed(true);
    setIsPaymentSuccessful(false);
    setIsShowPaymentForm(false);
  }

  useEffect(() => {
    if (isPaymentSuccessful && selectedProduct) {
      const amount = selectedProduct.amount / 100;
      // @ts-ignore
      window.dataLayer.push({'event': 'purchase', 'value': {
          transaction_id: orderId,
          value: amount,
          tax: 0,
          shipping: 0,
          currency: selectedProduct.currency,
          items: [
            {
              item_id: selectedProduct.id,
              item_name: selectedProduct.description,
              price: amount,
              quantity: 1
            }]
      }});
    }
  }, [isPaymentSuccessful])

  useEffect(() => {
    const product = fltrProducts.find(product => product.id === match.params.productId);
    // @ts-ignore
    window.dataLayer.push({ 'event': PageName.PAYMENT });
    if (product) {
      selectProduct(product);
    } else {
      throw new Error(`Product with id: ${match.params.productId} not found`);
    }
  }, [])

  let history = useHistory();

  const appleContainerRef = useRef(null)
  const googleContainerRef = useRef(null)

  if (isLoadingMerchantDate || !selectedProduct) {
    return (
      <div className={'payment-page-container'}>
        <Header />
        <div className='spinner-container' style={{ height: '100VH' }}>
          <MDSpinner size={100} singleColor={spinnerColor} />
        </div>
      </div>
    )
  }

  if (isShowPaymentForm && merchantData) {
    return <div className={'payment-page-container'}>
      <HeaderOnboarding
        onBack={() => {
          history.push(`/paywall`);
        }}
        isShowBack={true}
      />
      <OrderSummary
        product={selectedProduct}
      />
      <div className={getClassName('payment-body')}>
        <div className={'payments-title'}>Choose payment method</div>
        <div ref={appleContainerRef} id={'apple-pay-container'} className={'apple-pay-container'} />
        <div ref={googleContainerRef} id={'google-pay-container'} className={'google-pay-container'} />
        <PayPalView
          payPalScriptUrl={payPalScriptUrl}
          onSuccess={onSuccess}
          onFail={onFail}
          orderId={orderId}
          product={selectedProduct}
        />
        <div className={'or'}>or</div>
        <div className={'payments-title'}>Card payment details</div>
        <SolidgateForm
          onSuccess={onSuccess}
          onFail={onFail}
          merchantData={merchantData}
          appleContainerRef={appleContainerRef}
          googleContainerRef={googleContainerRef}
        />
      </div>
      <div className={'payment-agree'}>
        {"By continuing, I agree with "}
        <a href="https://onelightapps.io/w-terms-of-use" target='_blank'>Terms of Use</a>
        {" and "}
        <a href="https://onelightapps.io/w-privacy-policy" target='_blank'>Privacy Policy.</a>
        {selectedProduct.isLifetime ? null :
          ` Please note that your subscription will be automatically renewed at price of ${selectedProduct.total} at the end of chosen subscription term.`
        }
        {" You can cancel it by contacting our customer support team via email "}
        <a href={`mailto:support@onelightapps.io`}>support@onelightapps.io</a>
      </div>
    </div>;
  }

  if (isPaymentSuccessful) {
    return <div className={'payment-page-container'}>
      <Header />
      <PaymentSuccessful
        redirectAppUrl={redirectAppUrl}
        orderId={orderId}
      />
    </div>;
  }

  if (isPaymentFailed) {
    return <div className={'payment-page-container'}>
      <Header />
      <PaymentFailed
        setIsShowPaymentForm={() => {
          if (selectedProduct) {
            selectProduct(selectedProduct);
          }
          setIsShowPaymentForm(true)
        }}
        orderId={orderId}
      />
    </div>;
  }

  return <div className={'payment-page-container'}>
    <HeaderOnboarding
        onBack={() => {
          history.push(`/paywall`);
        }}
        isShowBack={true}
    />
  </div>;
};

const mapStateToProps = (state: any) => ({
  isShowPaymentForm: state.appState.isShowPaymentForm,
  merchantData: state.appState.merchantData,
  isLoadingMerchantDate: state.appState.isLoadingMerchantDate,
  payPalScriptUrl: state.appState.payPalScriptUrl,
  selectedProduct: state.appState.selectedProduct,
  redirectAppUrl: state.appState.redirectAppUrl,
  orderId: state.appState.orderId,
});

const mapDispatchToProps = (dispatch: any) => ({
  selectProduct: bindActionCreators(selectProduct, dispatch),
  setIsShowPaymentForm: bindActionCreators(setIsShowPaymentForm, dispatch),
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(Payment);
