import React from 'react';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {BrowserRouter, Route, useHistory, useLocation} from 'react-router-dom';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';
import reducer from './reducers';

import momentZ from 'moment-timezone';
import Main from "./components/main";
import Payment from "./components/payment";
import Paywall from "./components/paywall";
import Quiz from "./components/quiz";
import {withAuthenticator} from "./components/authorization";

momentZ.tz.setDefault('UTC');

const routsTemplates: any = {
  'default': [
    {path: '/', component: Main, order: 0},
    {path: '/quiz', component: Quiz, order: 1},
    {path: '/paywall', component: withAuthenticator(Paywall), order: 2},
    {path: '/payment/:productId', component: Payment, order: 3},
  ]
};

const HandlerLocation = ({routs}: {routs: any[]}) => {
  const history = useHistory();
  const location = useLocation();

  if (routs.length !== 0 && location.pathname === '/' && routs[0].path !== '/') {
    history.push(routs[0].path);
  }
  return null;
};

const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(createDebounce(),thunk)),
);

const WithProvider = () => {
  let routs: any[] = routsTemplates['default'];
  console.log('zxc')
  return <Provider store={store}>
    <BrowserRouter>
      <div  className="app-container">
        <HandlerLocation
            routs={routs}
        />
        <div className={'body-container'}>
          {
            routs.map((rout) => {
              // eslint-disable-next-line react/jsx-key
              return <Route exact path={rout.path} component={rout.component}/>;
            })
          }
        </div>
      </div>
    </BrowserRouter>
  </Provider>;
};

export default WithProvider;
