import React, {useEffect, useRef} from 'react';
import {getClassName} from "../../utils/StyleUtils";
import {fltrProducts} from "../../models/Product";

interface Props {
    selectProductId: (productId: string) => void
    selectedProductId: string
}

export const PaywallProducts = ({selectedProductId, selectProductId}: Props) => {
    const refBody = useRef(null);

    useEffect(() => {
        if(refBody && refBody.current) {
            const x = 0.482908;
            const y = 23.344651;
            const h = window.innerHeight;
            const hy = h * y;
            const hx = h * x;
            const d = hx * hx - 4 * hy;
            let z1 = (2 * hy) / (hx - Math.sqrt(d));
            z1 = z1 > 380 ? 380 : z1;
            // @ts-ignore
            refBody.current.style.paddingTop = z1 + 'px';
        }
    }, [])

    return <div ref={refBody} className={getClassName('paywall-products-container')}>
    <div className={'paywall-products-list'}>
        <div className={'paywall-body-title'}>
            Get aesthetic presets tailored to you!
        </div>
        <div className={'paywall-choose-plan-title'}>
            Choose your plan
        </div>
        {
            fltrProducts.map(product => {
                const isActive = product.id === selectedProductId;

                let amount = product.amount;
                let priceD = Math.trunc(amount / 100);
                let priceC = (priceD).toFixed(2);

                if(product.purchasesInYear) {
                    priceD = (priceD * product.purchasesInYear) / 365;
                    priceC = (priceD).toFixed(2);
                    priceD = Number(priceD.toFixed(0));
                }
                priceC = priceC.slice(priceC.length - 2, priceC.length)

                return <div key={product.id} className={getClassName(isActive ? 'paywall-product-active' : 'paywall-product')} onClick={() => {selectProductId(product.id)}}>
                    {product.save ? <div className={(isActive ? 'paywall-product-price-save-active' : 'paywall-product-price-save')}>MOST POPULAR</div> : null}
                    <div className={'paywall-product-body'}>
                        <div className={isActive ? 'product-circle-active' : 'product-circle'}></div>
                        <div className={'paywall-product-price-container'}>
                            <div className={'paywall-product-price-titles-container'}>
                                <div className={'paywall-product-price-title'}>
                                    {product.description}
                                </div>
                                {
                                    product.isLifetime ? null : <div className={'paywall-product-price-subtitle'}>
                                        {product.summaryPrice ? <span style={{paddingRight: '4px', textDecorationLine: 'line-through'}}>{product.summaryPrice}</span> : null}
                                        {product.total}
                                    </div>
                                }
                            </div>
                            <div className={('paywall-product-price' + (isActive ? '-active' : ''))}>
                                $
                                <span style={{fontSize: '48px', lineHeight: '42px'}}>{priceD}</span>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: "center"}}>
                                    {priceC}
                                    {!product.isLifetime ? <span className={'per-day'}>per day</span> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            })
        }
    </div>

      <div className={'paywall-guarantee-container'}>
          30-day money-back guarantee
      </div>
  </div>
}
