import React, {useEffect, useRef, useState} from 'react';
import {getClassName} from "../../utils/StyleUtils";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// @ts-ignore
import {PaywallResources, ReviewResources} from "../../models/Resources";
import {range} from "../../utils/ArrayUtils";

const style = {width: '345px', marginRight: '5px', marginLeft: '5px'}
export const DescriptionApp = () => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const settings = {
        dots: false,
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        accessibility: false,
        touchThreshold: 20,
        variableWidth: true,
        initialSlide: 0,
        centerMode: true,
        afterChange: (index: number) => {
                setActiveSlideIndex(index)
        },
    };
    return <div className={getClassName('review-container')} id={'description'}>
        <img width={'276px'} style={{paddingTop: '48px', paddingBottom: '48px'}} src={ReviewResources.reviewRating.blobSrc}/>

        <div className={getClassName('title')}>
          Edit photos & videos like a<br/> PRO with one tap!
      </div>
        <div style={{width: '100%', height: '240px', marginTop: '32px'}}>
            <Slider {...settings}>
                <div>
                    <img style={style} src={PaywallResources.beforeAfter1.blobSrc}/>
                </div>
                <div>
                    <img style={style} src={PaywallResources.beforeAfter2.blobSrc}/>
                </div>
                <div>
                    <img style={style} src={PaywallResources.beforeAfter3.blobSrc}/>
                </div>
                <div>
                    <img style={style} src={PaywallResources.beforeAfter4.blobSrc}/>
                </div>
            </Slider>
        </div>
        <div className={'before-after-dots-container'}>
            {range(4).map((dotIndex) => {
                return <div key={dotIndex} className={'before-after-dot' + (dotIndex === activeSlideIndex ? '-active' : '')}/>
            })}
        </div>
        <div className={getClassName('title')} style={{paddingTop: '48px'}}>
            What you get with FLTR
        </div>
        <div className={'paywall-description-list'}>
            <div className={'paywall-description'}>
                ️1500+ presets for Lightroom Mobile
            </div>
            <div className={'paywall-description'}>
                ️Professional-looking photos & videos
            </div>
            <div className={'paywall-description'}>
                ️️More attention in social media
            </div>
            <div className={'paywall-description'}>
                ️️Time-saving by up to 80%
            </div>
        </div>
  </div>
}
